import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { ConfigurationService } from '../helper-services/configuration.service';
import { AuthService } from '../services/auth.service';
import { LocalStorageService } from '../helper-services/local-storage.service';

@Injectable({
  providedIn: "root"
})
export class PublicSiteAuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private cookieService: CookieService,
    private configurationService: ConfigurationService,
    private localStorageService: LocalStorageService,
    private router: Router) { }

  async handleLoggedOut(currentRoute: string) {
    // console..log('user not logged in');
    // if this is not landing page (requires loggedin) then signout auth website and resolve true
    if (currentRoute !== 'landing') {
      await this.authService.logoutClient(false);
      return true;
    } else {
      // Navigate to the login page
      this.router.navigate(['/login']);
      return false;
    }
  }

  handleLoggedIn(currentRoute: string) {
    // console..log('user logged in');
    // if this is the auth login page then signout auth website and resolve true
    if (currentRoute !== 'landing') {
      this.router.navigate(['/landing']);
      return false;
    } else {
      return true;
    }
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const currentRoute = route.routeConfig.path;
    // console..log('==> ', currentRoute);
    if (this.configurationService.config.env.toUpperCase() !== 'LOCAL') {
      // in case of local, not verifying csrf cookie because localhost cann't access pinonclick cookie
      const cssToken = this.authService.csstCookie;
      // console..log("csrfToken: " + cssToken);
      if (!cssToken) {   // means authentication never happend or signed out
        // console..log('inside -1 ');
        return await this.handleLoggedOut(currentRoute);
      }
      if (!cssToken) {// mean user is signOut
        return await this.handleLoggedOut(currentRoute);
      } else if (!this.authService.isEqualLocalAndCookieCsst) {
        const isSessionReloaded = await this.authService.reloadSession(cssToken);
        if (!isSessionReloaded) {
          return await this.handleLoggedOut(currentRoute);
        }
      }
    }
    // console..log('outside -1 ');
    const user = await this.authService.isAuthenticated();
    if (user) {
      return this.handleLoggedIn(currentRoute);
    } else {
      return await this.handleLoggedOut(currentRoute);
    }
  }
}
