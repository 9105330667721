import { NotificationWaitComponent } from './components/notification-wait.component';
import { MaterialModule } from '@otrack-lib/static-modules/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WizardStepperComponent } from './wizard-stepper/wizard-stepper.component';

@NgModule({
  declarations: [NotificationWaitComponent, WizardStepperComponent],
  imports: [
    CommonModule,
    MaterialModule,
  ],
  exports: [MaterialModule, WizardStepperComponent],
  entryComponents: [NotificationWaitComponent]
})
export class SharedModule { }
