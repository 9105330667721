import { AppEffects } from '@otrack-lib/core/store/app.effects';
import { appReducers } from '@otrack-lib/core/store/app.reducer';
import { StoreModule } from '@ngrx/store';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IConfiguration } from '@otrack-lib/models/configuration.model';
import { environment } from '@public-env/environment';
import { CoreModule } from '@otrack-lib/core/core.module';
import { ErrorHandlerInterceptor } from '@otrack-lib/core/interceptors/error-interceptor.service';
import { GlobalErrorHandler } from '@otrack-lib/core/global-error-handler';
import { MaterialModule } from '@otrack-lib/static-modules/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { CommonComponentModule } from '@otrack-lib/common/components/common-component.module';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { EffectsModule } from '@ngrx/effects';
import { TokenInterceptor } from '@otrack-lib/core/interceptors/token.interceptor.service';

const configuration: IConfiguration = {
  env: environment.env,
  apiURL: environment.apiURL,
  mode: environment.mode,
  production: environment.production,
  appDomain: environment.appDomain,
  signupFunction: environment.signupFunction,
  checkstatusFunction: environment.checkstatusFunction,
  signoutFunction: environment.signoutFunction,
  loginPageUrl: environment.loginPageUrl,
  firebase: environment.firebase,
  sentryDsn: environment.sentryDsn
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    SharedModule,
    CommonComponentModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot([AppEffects]),
    CoreModule.forRoot(configuration), // Inject configuration in Library coreModule
    AppRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule // imports firebase/auth, only needed for auth features
    //  RouterModule.forRoot([], { initialNavigation: 'enabled' })
  ],
  providers: [
    CookieService,
    AngularFireAuthGuard,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
