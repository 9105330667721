import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompanyResolver } from '@otrack-lib/core/resolvers/company-resolver.service';
import { redirectLoggedInTo, AngularFireAuthGuard, redirectUnauthorizedTo, canActivate } from '@angular/fire/auth-guard';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { ConfigurationService } from '@otrack-lib/core/helper-services/configuration.service';
import { PublicSiteAuthGuard } from '@otrack-lib/core/guards/public-site-auth.guard';

// const redirectUnauthorizedToLogin = (next) => map(user => {
//   user ? [next.url] : ['login'];
// });

// const redirectLoggedInToLanding = (next) => map(user => {
//   user ? ['landing'] : [next.url];
// });

export const rootRouterConfig: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login', loadChildren: () => import('@public-app/modules/login/login.module')
      .then(m => m.LoginModule), canActivate: [PublicSiteAuthGuard]
  },
  {
    path: 'forgot-password', loadChildren: () => import('@public-app/modules/forgot-password/forgot-password.module')
      .then(m => m.ForgotPasswordModule), canActivate: [PublicSiteAuthGuard]
  },
  {
    path: 'landing', loadChildren: () => import('@public-app/modules/landing/landing.module')
      .then(m => m.LandingModule), canActivate: [PublicSiteAuthGuard], resolve: { cres: CompanyResolver },
  },
  { path: 'demo', loadChildren: () => import('@public-app/modules/demo/demo.module').then(m => m.DemoModule), canActivate: [PublicSiteAuthGuard] },
  { path: 'register', loadChildren: () => import('@public-app/modules/register/register.module').then(m => m.RegisterModule), canActivate: [PublicSiteAuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(rootRouterConfig)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(
    public cookieService: CookieService,
    public configurationService: ConfigurationService) { }
}
