import { ErrorService } from '@otrack-lib/core/services/error.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfigurationService } from '@otrack-lib/core/helper-services/configuration.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NotificationService } from '@otrack-lib/core/helper-services/notification.service';

@Component({
  selector: 'otrack-web-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(
    //  @Inject(WINDOW) private window: Window,

    private configurationService: ConfigurationService,
    private errorService: ErrorService,
    private notificationService: NotificationService,
    private router: Router
  ) {

  }

  ngOnInit(): void {

    // const logOutSubscription = this.errorService.IsUserLogOut.subscribe(val=>{
    //   if(val === true) {
    //     this.router.navigate(['/login']);
    //   }
    //   });

    //   this.unsubscribe.push(logOutSubscription);


    //   const messageShowSubscription = this.errorService.IsErrorMessage.subscribe((val:string)=>{
    //       console.error(val);
    //       if ( val && val.length > 0){
    //         this.notificationService.showError(val);
    //       }


    //   });
    //   this.unsubscribe.push(messageShowSubscription);

  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
  }
}
